<template>
  <div class="promoted-mode">
    <div class="btn-head">
      <div class="btn-item" :class="{'active-btn-item': activeBtn === 'interaction-body'}" @click="activeBtn = 'interaction-body'">交互模式</div>
      <div class="btn-item" :class="{'active-btn-item': activeBtn === 'personal-body'}" @click="activeBtn = 'personal-body'">进阶模式</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotedMode',
  props: {
    active: {
      type: String,
      default: 'interaction-body'
    }
  },

  data() {
    return {
      activeBtn: ''
    }
  },
  watch: {
    active: {
      handler(val) {
        this.activeBtn = val
      },
      immediate: true
    },
    activeBtn(val) {
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped lang="less">
.promoted-mode {
  .btn-head {
    height: 32px;
    display: flex;
    line-height: 32px;
    text-align: center;
    .btn-item{
      width: 120px;
      background: #f2f3f5;
      border-radius: 2px 2px 2px 2px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #606266;
      cursor: pointer;
    }
    .active-btn-item{
      background: #437aec;
      color: #fff;
    }
  }
}
</style>
