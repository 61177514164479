<template>
  <div class="writ-execution">
    <div class="title">执行命令</div>
    <div class="command">
      <el-input
        v-model="command"
        type="textarea"
        resize="none"
        :autosize="{ minRows: 8, maxRows: 8 }"
        placeholder="请输入命令"
        :spellcheck="false"
        @input="inputCommand"
      /></div>
  </div>
</template>

<script>
export default {
  name: 'WritExecution',
  props: {
    script: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      command: "mpirun -hosts `scontrol show hostname $SLURM_JOB_NODELIST |tr '\n' ',' | head -c-1` -np $SLURM_NTASKS vasp_std"
    }
  },
  watch: {
    script: {
      immediate: true,
      handler(val) {
        this.command = val
      }
    }
  },
  methods: {
    inputCommand() {
      this.$emit('getCommand', this.command)
    }
  }
}
</script>

<style scoped lang="less">
.writ-execution {
  position: relative;
  border: 1px solid #e8e8e8;
  padding: 30px 20px;
  background: rgba(245, 247, 250, 0.5);
  margin-top: 30px;
  .title {
    position: absolute;
    top: -15px;
    left: 20px;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e8e8e8;
  }
  .command {
    background: #000;
    color: #fff;
    min-height: 167px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    padding: 20px 30px;
    ::v-deep .el-textarea__inner{
      padding: 0;
      background-color: #000;
      border: none;
      box-shadow: none;
      color: #ffffff;

    }
  }
}
</style>
